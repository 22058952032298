import React from "react";

import {
  PlainTextEditor,
  Editable
} from 'react-easy-editables';


class EmbeddedIframeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { content: this.props.content };
  }

  handleEditorChange = field => item => {
    this.setState({
      content: {
        ...this.state.content,
        [field]: {
          ...item
        }
      }
    });
  }

  getIframeAttribute = (attr, string) => {
    const regex = ` ${attr}=["'](.*?)["']`
    const match = string.match(regex)
    if (match) {
      return match[1]
    } else {
      return ''
    }
  }

  handleChangeEmbedCode = ({ text }) => {
    const src = this.getIframeAttribute('src', text)
    const title = this.getIframeAttribute('title', text)
    const height = this.getIframeAttribute('height', text)
    const width = this.getIframeAttribute('width', text)

    this.setState({
      content: {
        ...this.state.content,
        embedCode: { text },
        src: { text: src },
        title: { text: title },
        height: { text: height },
        width: { text: width }
      }
    })
  }

  render() {
    const { content } = this.state;
    const { embedCode } = content;

    return (
      <div className="wrapper">
        <div className="form-field">
          <label htmlFor="iframe-embed-code">Embed code:</label>
          <PlainTextEditor
            name="iframe-embed-code"
            EditorProps={{id: "iframe-embed-code"}}
            content={embedCode}
            handleEditorChange={this.handleChangeEmbedCode}
          />
        </div>
      </div>
    );
  }
}


const EmbeddedIframe = props => {
  const content = props.content || {};
  const { title, src, height, width } = content;
  const ratio = (parseInt(height.text) / parseInt(width.text)) * 100;

  const iframeContainerStyles = {
    position: "relative",
    paddingBottom: `${ratio}%`,
    height: 0,
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
  }

  const iframeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }


  const handleSave = newContent => {
    props.onSave(newContent)
  }

  return (
    <Editable
      Editor={EmbeddedIframeEditor}
      handleSave={handleSave}
      content={content}
      {...props}
    >
      <div className="iframe-container" style={iframeContainerStyles}>
        <iframe
          title={title.text}
          src={src.text}
          className="iframe"
          style={iframeStyles}
          allowFullScreen={true}
          height={`${height.text}px`}
          width={`${width.text}px`}
          frameBorder="0"
        />
      </div>
    </Editable>
  );
};

EmbeddedIframe.defaultProps = {
  content: {
    "embedCode": { "text": `<iframe width="560" height="315" src="https://www.youtube.com/embed/LwpH7lUIFsQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` },
    "src": { "text": "https://www.youtube.com/embed/LwpH7lUIFsQ" },
    "title": { "text": "Embedded video" },
    "height": { "text": "315" },
    "width": { "text": "560"}
  }
}

export default EmbeddedIframe;
